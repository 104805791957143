<template>
    <div>
      <div class="flex md12">
        <va-card :title="'merge account'">
            <template slot="actions">
                <div class="row">
                    <va-button
                        @click="merge();"
                    >
                    MERGE
                    </va-button>
                </div>
            </template>
          <div class="row align--center">
            <div class="flex xs12 md6">
              <va-input
                :value="term"
                :placeholder="'Search by name or email or ID'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
  
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            hoverable
          >
            <template
              slot="avatar"
              slot-scope="props"
            >
              <va-avatar>
                <img
                  :src="props.rowData.photoURL"
                  class="avatar data-table-server-pagination---avatar"
                >
              </va-avatar>
            </template>
            <template
              slot="actions"
              slot-scope="props"
            >
              <va-checkbox
                @input="selectAccount(props.rowData.uid)"
                v-model="props.rowData.checked"
              />
            </template>
          </va-data-table>
        </va-card>
      </div>

      <div class="flex md12">
        <va-card :title="'account to be removed'">
          <div class="row align--center">
            <div class="flex xs12 md6">
              <va-input
                :value="serchInput"
                :placeholder="'Search by name or email or ID'"
                @input="searchAccToRemove"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
  
          <va-data-table
            :fields="fields"
            :data="filteredDataToRemove"
            :per-page="parseInt(perPage)"
            :loading="loading"
            hoverable
          >
            <template
              slot="avatar"
              slot-scope="properties"
            >
              <va-avatar>
                <img
                  :src="properties.rowData.photoURL"
                  class="avatar data-table-server-pagination---avatar"
                >
              </va-avatar>
            </template>
            <template
              slot="actions"
              slot-scope="properties"
            >
              <va-checkbox
                @input="selectAccount(properties.rowData.uid, true)"
                v-model="properties.rowData.checked"
              />
            </template>
          </va-data-table>
        </va-card>
      </div>

    </div>
  </template>
  
  <script>
  
  import firebase from 'firebase';
  import axios from '@/scripts/interceptor.js'
  import {debounce} from 'lodash';
  export default {
    name: 'MergeAccounts',
    data() {
      return {
        email: '',
        loading: true,
        emailErrors: [],
        users: [],
        usersToRemove: [],
        term: null,
        serchInput: null,
        perPage: '5',
        perPageOptions: ['5','10', '50', '100', '250'],
        selectedAccount: null,
      };
    },
    computed: {
      fields() {
        return [{
          name: '__slot:avatar',
          width: '30px',
          height: '45px',
          dataClass: 'text-center',
        }, {
          name: 'uid',
          title: 'id',
          sortField: 'uid',
          width: '20%',
        }, {
          name: 'displayName',
          title: this.$t('tables.headings.name'),
          sortField: 'displayName',
          width: '30%',
        }, {
        
          name: 'email',
          title: this.$t('tables.headings.email'),
          sortField: 'email',
          width: '30%',
        },
        {
          name: '__slot:actions',
          title: 'select',
          checked: false,
          dataClass: 'text-right',
        }];
      },
      filteredData() {
        return this.retrieveData();
      },
      filteredDataToRemove() {
        return this.retrieveData(true);
      },
    },
    async created() {
      firebase.firestore().collection('users').orderBy('email').get().then((snapshot)=>{
        snapshot.docs.forEach((user) => {
          const userData = user.data();
          userData.uid = user.id;
          userData.email = this.truncate_email(userData.email);
          userData.checked = false;
          if (!userData.photoURL) {
            userData.photoURL = 'https://nihss3.com/assets/defaultProfilePic.png';
          }
          this.users.push(userData);
          const copiedUser = JSON.parse(JSON.stringify(userData));
          this.usersToRemove.push(copiedUser);
        });
      });
      this.loading = false;
    },
    methods: {
        retrieveData(accToRemove = false) {
            let data = this.users;
            let input = this.term;
            if (accToRemove) {
                data = this.usersToRemove;
                input = this.serchInput;
            }
            if (!input|| input.length < 1) {
                return data;
            }
            
            return data.filter((item) => {
                let returnUser = false;
                if (item.displayName) {
                    if (item.displayName.toLowerCase().indexOf(input.toLowerCase()) !== -1) {
                    returnUser = true;
                    }
                }
                if (item.email.toLowerCase().indexOf(input.toLowerCase()) !== -1) {
                    returnUser = true;
                }
        
                if (item.uid.toLowerCase().indexOf(input.toLowerCase()) !== -1) {
                    returnUser = true;
                }
                return returnUser;
            });
        },

      truncate_email($email, $nbrChar = 40, $append='...') {
        if ($email.indexOf('@') !== -1 && $email.length >= $nbrChar) {
          const splitEmail = $email.split('@');
          splitEmail[0] = splitEmail[0].slice(0, $nbrChar) + $append;
          return splitEmail[0] + '@' + splitEmail[1];
        }
        if ($email.length > $nbrChar) {
          $email = $email.slice(0, $nbrChar);
          $email += $append;
        }
        return $email;
      },
   
      search: debounce(function(term) {
        this.term = term;
        if (term && !this.serchInput) {
            this.serchInput = term;
        }
      }, 100),

      searchAccToRemove: debounce(function(term) {
        this.serchInput = term;
      }, 100),
      selectAccount(id, userToRemove = false) {
        if (userToRemove) {
            this.usersToRemove = this.checkAccount(this.usersToRemove, id);
        } else {
            this.users = this.checkAccount(this.users, id);
        }
      },
      checkAccount(data, id) {
        if (!data) {
            return [];
        }
        return data.map((el) => {
            if (el.uid === id) {
                el.checked = true;
            } else {
                el.checked = false;
            }
            return el;
        });      
      },

      async merge() {
        this.loading = true;
        const mergeAccount = this.users.find(el => el.checked);
        const removeAccount = this.usersToRemove.find(el => el.checked);
        if (mergeAccount && removeAccount) {
  
            const mergeAccountIsSubscribed = await axios.get(window.firebaseURL + `api/users/subscribed/${mergeAccount.uid}`);
            const removeAccountIsSubscribed = await axios.get(window.firebaseURL + `api/users/subscribed/${removeAccount.uid}`);

            if (mergeAccountIsSubscribed?.data?.data?.status && removeAccountIsSubscribed?.data?.data?.status) {
                this.alertError('Both account are subscribed. Remove one subscription.');
            } else if (!mergeAccountIsSubscribed?.data?.data?.status && !removeAccountIsSubscribed?.data?.data?.status) {
                this.alertError('Both account are unsubscribed. Subscription required for at least one of them.');
            } else {
                if (!mergeAccountIsSubscribed?.data?.data?.status) {
                    await this.makeMergeAccountSubscribed(removeAccount, mergeAccount);                    
                }
                await this.copyCollection('users', removeAccount.uid, 'Trainings', mergeAccount.uid);
                await this.copyCollection('users', removeAccount.uid, 'TrainingStats', mergeAccount.uid);
                await this.copyCollection('users', removeAccount.uid, 'Demonstration', mergeAccount.uid);
                await this.copyCollection('users', removeAccount.uid, 'AdminNotifications', mergeAccount.uid);
                await this.copyCollection('users', removeAccount.uid, 'Exams', mergeAccount.uid);

                await this.inactivateAccount(removeAccount.uid, mergeAccount.uid);                              
                this.alertSucces('Accounts Merged')                
                this.selectAccount(null);
                this.selectAccount(null, true);
            }
            this.loading = false;
        } else {
            this.alertError('You need to select the merge account to merge and the one to remove');
            this.loading = false;
        }
      },

      async copyCollection(mainCollectionName, removeId, cpCollectionName, mergeId) {
        try {
            const referenceRemove = firebase.firestore().collection(mainCollectionName).doc(removeId);
            const referenceMerge = firebase.firestore().collection(mainCollectionName).doc(mergeId);
            const snapshotRemove = await referenceRemove.collection(cpCollectionName).get();            
            if (snapshotRemove.empty) {
                return;
            }
            snapshotRemove.docs.forEach(async (elem)=>{
                await referenceMerge.collection(cpCollectionName).doc(elem.id).set(elem.data());
            });
        } catch (error) {
            console.log(error);
            return;
        }        
      },

      async makeMergeAccountSubscribed(removeAccount, mergeAccount) {
        try {
            const customerRef = firebase.firestore().collection('customers');
            const currentSubscription = await customerRef.doc(removeAccount.uid).collection('subscriptions').get();
            if (!currentSubscription.empty) {
                for (const elem of currentSubscription.docs) {
                    await customerRef.doc(mergeAccount.uid).collection('subscriptions').doc(elem.id).set(elem.data());
                }
            }
        } catch (error) {
            console.log(error);
        }
        
      },

      alertError(msg) {
        this.$swal.fire({
            title: 'Error!',
            text: msg,
            icon: 'error',
            confirmButtonText: 'Ok'
        });
      },

      alertSucces(text) {
        this.alertMessage('Success!', text, 'success', 'Ok')
      },

      alertMessage(title, text, icon, confirmButtonText) {
        this.$swal.fire({ title, text, icon, confirmButtonText }); 
      },

      async inactivateAccount(id, mergedBy) {
        try {
            await firebase.firestore().collection('users').doc(id).update({active: false, mergedBy})            
            return true
        } catch (error) {
            return false
        }        
      }
    },
  
  };
  </script>
  
  <style lang="scss">
  .avatar {
    max-width: 100px;
    height: auto;
  }
  </style>
  